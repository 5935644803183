import React, { useEffect, useContext } from "react";
import Header from "./components/Header";
import CameraComponent from "./components/Camera";
import Survey from "./components/Survey";
import "./App.css";
import "./fonts/magnat-text-light.ttf";
import "./fonts/FontAwesome.ttf";
import useProducts from "./utils/hooks/useProducts";
import ModelLoadingContext, {
  ModelLoadingProvider,
} from "./contexts/ModelLoadingContext";
import * as faceapi from "face-api.js";

const App: React.FC = () => {
  const [view, setView] = React.useState(true);
  const { setIsModelLoaded } = useContext(ModelLoadingContext);
  const changeView = (set: boolean) => {
    setView(set);
  };
  const { products, getProducts } = useProducts();
  useEffect(() => {
    async function loadModels() {
      getProducts();
      try {
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        ]);
        // Set the loaded state to true
        setIsModelLoaded(true);
        console.log(products);
      } catch (error) {
        console.error("Error loading models:", error);
      }
    }
    loadModels();
  }, []);

  return (
    <ModelLoadingProvider>
      <Header />
      {!view ? (
        <CameraComponent products={products} />
      ) : (
        <Survey onComplete={() => changeView(false)} />
      )}
    </ModelLoadingProvider>
  );
};

export default App;
