import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { ThemeProvider, Typography, useMediaQuery, Link } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import "./index.css";

const fetch = require("node-fetch");

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

const StyledTextField = styled(TextField)({
  margin: "8px",
  width: "80%",
  "& label.Mui-focused": {
    color: "black",
  },
});

const SuccessMessage = styled(Typography)({
  margin: "16px",
  color: "black",
});

interface IEmailForm {
  qa: any;
  templateParams: any;
}

const EmailForm: React.FC<IEmailForm> = ({ qa, templateParams }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  console.log(templateParams);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(false);
    setShowSuccessMessage(false); // Hide success message when email changes
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000", //your color
      },
    },
  });

  const send = (event: any) => {
    event.preventDefault();
    const url1 = `https://a.klaviyo.com/client/subscriptions/?company_id=${process.env.REACT_APP_COMPANY_ID}`;
    const options1 = {
      method: "POST",
      headers: { revision: "2024-02-15", "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          type: "subscription",
          attributes: {
            profile: {
              data: {
                type: "profile",
                attributes: {
                  email: email,
                  properties: {
                    question1: qa.split(";")[0].split("answer:")[1],
                    question2: qa.split(";")[1].split("answer:")[1],
                    question3: qa.split(";")[2].split("answer:")[1],
                    total: templateParams.total,
                    wrinkles: templateParams.wrinkles,
                    firmness: templateParams.firmness,
                    redness: templateParams.redness,
                    darkSpots: templateParams.darkSpots,
                    oilPores: templateParams.oilPores,
                    texture: templateParams.texture,
                    product1: templateParams.product1Href,
                    product2: templateParams.product2Href,
                    product3: templateParams.product3Href,
                    product4: templateParams.product4Href,
                    product5: templateParams.product5Href,
                    product6: templateParams.product6Href,
                  },
                },
              },
            },
          },
          relationships: { list: { data: { type: "list", id: "SLgBAS" } } },
        },
      }),
    };

    const url = `https://a.klaviyo.com/client/events/?company_id=${process.env.REACT_APP_COMPANY_ID}`;
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        revision: "2024-02-15",
      },
      body: JSON.stringify({
        data: {
          type: "event",
          attributes: {
            properties: templateParams,
            metric: {
              data: { type: "metric", attributes: { name: "Skin Analysis" } },
            },
            profile: {
              data: {
                type: "profile",
                attributes: {
                  email: email,
                  properties: {
                    question1: qa.split(";")[0].split("answer:")[1],
                    question2: qa.split(";")[1].split("answer:")[1],
                    question3: qa.split(";")[2].split("answer:")[1],
                    total: templateParams.total,
                    wrinkles: templateParams.wrinkles,
                    firmness: templateParams.firmness,
                    redness: templateParams.redness,
                    darkSpots: templateParams.darkSpots,
                    oilPores: templateParams.oilPores,
                    texture: templateParams.texture,
                    product1: templateParams.product1Href,
                    product2: templateParams.product2Href,
                    product3: templateParams.product3Href,
                    product4: templateParams.product4Href,
                    product5: templateParams.product5Href,
                    product6: templateParams.product6Href,
                  },
                },
              },
            },
          },
        },
      }),
    };

    fetch(url, options)
      .then((res: any) => {
        fetch(url1, options1)
          .then((res: any) => setShowSuccessMessage(true))
          .then((json: any) => console.log(json))
          .catch((err: any) => console.error("error:" + err));
      })
      .then((json: any) => console.log(json))
      .catch((err: any) => console.error("error:" + err));
  };

  const validateEmail = (email: string) => {
    // Regular expression for basic email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <FormContainer>
      <Typography
        variant="h4"
        sx={{
          marginTop: 10,
          marginBottom: 6,
          width: isSmallScreen ? 270 : 450,
          textAlign: "left",
          fontFamily: "'magnat'",
        }}
      >
        Get Your Personalised Routine & 30% Off Voucher
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: 1,
          marginBottom: 5,
          width: isSmallScreen ? 270 : 450,
          fontFamily: "'magnat'",
        }}
      >
        Enter your email to get your results delivered right to your inbox.
        Plus, sign up to our email communications to get 30% off on your first
        order,{" "}
        <Link
          target="_blank"
          href="https://avant-skincare.com/fr/content/3-terms-and-conditions-of-use"
          sx={{
            marginTop: 1,
            marginBottom: 5,
            width: isSmallScreen ? 270 : 450,
            fontFamily: "'magnat'",
            fontSize: "1rem",
            color: "grey",
            textDecoration: "none",
          }}
        >
          terms and conditions
        </Link>{" "}
        apply.
      </Typography>
      <ThemeProvider theme={theme}>
        <StyledTextField
          id="standard-required"
          label="Email"
          variant="standard"
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? "Invalid email" : ""}
          sx={{
            width: isSmallScreen ? 270 : 450,
            ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "black !important",
              },
          }}
        />
      </ThemeProvider>
      <button
        onClick={send}
        className={`button ${!validateEmail(email) ? "button-disabled" : ""}`}
        disabled={!validateEmail(email)}
      >
        Email me
      </button>
      {showSuccessMessage && (
        <SuccessMessage sx={{ marginTop: 5 }}>
          ✓ Successfully sent to your email.
        </SuccessMessage>
      )}
    </FormContainer>
  );
};

export default EmailForm;
